import { CountriesList } from '@client/components/CountriesList';
import React from 'react';
import { isEven } from 'is-even';

// noinspection JSUnusedGlobalSymbols
export default function Home() {
  return (
    <div className="container mx-auto">
      <h1 className="text-4xl font-bold">Countries list</h1>
      <button
        type="button"
        onClick={() => {
          throw new Error('Sentry Frontend Error');
        }}
      >
        Throw error here
      </button>
      {isEven(2) ? <h1>Even</h1> : <h1>Odd</h1>}
      <CountriesList />
    </div>
  );
}
